export const pt = {
  tag: "pt-BR",
  header: {
    title: "WYD {{server}} Ranking",
    description: "Descubra os jogadores mais insanos do servidor",
    description2: "Jogadores que estiverem inativos por mais de 3 meses serão removidos do ranking.  Caso o jogador faça login no jogo, ele estará automaticamente qualificado a participar do ranking."
  },
  categories: {
    title: "Categorias",
    level: "Nível",
    kills: "Abates",
    raWins: "Vitórias na Arena Real",
    raKills: "Abates na Arena Real",
    seasonLevel: "Servidor de Temporada",
    fameGuild: "Fame Guild",
  },
  filters: {
    guildMark: "Guild",
    kingdom: "Reino",
  },
  remove: "Remover Filtros",
  activefilter: "Filtro Ativo",
};
