import React, { useState, useEffect } from "react";
import styled from "styled-components";
import AngleUp from "../assets/angle-up-solid.svg";
import AngleDown from "../assets/angle-down-solid.svg";
import { translate } from "../services/i18n";
import axios from "axios";
const Container = styled.div`
  background-color: rgba(0, 0, 0, 0.7);
  margin-bottom: 15px;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #888888aa;

  button {
    background: transparent;
    border: 0;
    padding: 10px 15px;
    color: white;
    font-size: 16px;
    &:hover,
    &:focus,
    &.active {
      outline: none;
      cursor: pointer;
      background-color: #605a5a;
    }
  }
`;
const ToggleButton = styled.button`
  padding: 2px 15px 0 !important;
  img {
    width: 20px;
  }
`;

const DivFilters = styled.div`
  width: fit-content;
`;

const Expanded = styled.div`
  color: white;
  font-size: 14px;
  padding: 10px;

  p {
    margin-top: 0;
  }
`;

const Filters = (props) => {
  const { filters, onSelectFilter } = props;

  const [expanded, setExpanded] = useState(false);
  const [currentFilter, setCurrentFilter] = useState("");
  const [activeFilter, setActiveFilter] = useState(false);

  let remove = "remove";
  let activefilter = "activefilter";

  //Esse useEffect ta fazendo eu não conseguir escolher dois filters juntos, porém ele
  // ta fazendo com que quando tenha filtre e passe
  useEffect(() => {
    if (currentFilter.values) {
      setCurrentFilter("");
      onSelectFilter("");
      setActiveFilter(false);
    }
  }, []);

  return (
    <>
      {
        <Container>
          <Header>
            <DivFilters>
              {filters &&
                filters.map((el) => {
                  const { filter } = el;

                  let active = filter === currentFilter.filter ? "active" : "";

                  return (
                    <button
                      className={active}
                      key={filter}
                      onClick={() => {
                        setCurrentFilter(el);
                        setExpanded(true);
                      }}
                    >
                      {translate(`filters.${filter}`, { defaultValue: filter })}
                    </button>
                  );
                })}
            </DivFilters>
            <ToggleButton
              onClick={() => {
                setExpanded(!expanded);
              }}
              data-testid="toggle-button-expand"
            >
              <img src={expanded ? AngleUp : AngleDown} alt="" />
            </ToggleButton>
          </Header>
          {expanded ? (
            <Expanded>
              <div>
                {Array.isArray(currentFilter.values) &&
                  currentFilter.values.map((value) => {
                    let endUrl = "";
                    if (value === "blue" || value === "red") {
                      endUrl = `${value}.gif`;
                    } else {
                      endUrl = `img_guilds/global/${value}.bmp`;
                    }
                    return (
                      <button
                        href=""
                        style={{
                          padding: "5px",
                          backgroundColor: "Transparent",
                          border: "none",
                        }}
                        key={value}
                        onClick={() => {
                          onSelectFilter(currentFilter.filter, value);
                          setActiveFilter(true);
                        }}
                      >
                        <img
                          src={`https://wydmisc.raidhut.com.br/guild/${endUrl}`}
                        ></img>
                      </button>
                    );
                  })}
              </div>
              {activeFilter ? <div>{translate(activefilter)}</div> : <></>}
              <button
                onClick={(e) => {
                  onSelectFilter(currentFilter.filter);
                  setActiveFilter(false);
                }}
              >
                {translate(remove)}
              </button>
            </Expanded>
          ) : (
            <></>
          )}
        </Container>
      }
    </>
  );
};

export default Filters;
