import React from 'react'
import styled from 'styled-components'
import Table from './table'
import Loader from './loader'

const RankDiv = styled.div`
width: 100%;
flex-grow: 1;
padding: 15px;
overflow: auto;
background: white;

.loading {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
`

const Rank = (props) => {
    const { loading, data, currentCategory } = props;
    
    return (
        <RankDiv>
            {
                !loading ? (
                    <Table data={data} category={currentCategory}/>
                ):(
                    <div className="loading">
                        <Loader color="#3a3a3a88" size="60px"></Loader>
                    </div>
                )
            }
        </RankDiv>
    )
}

export default Rank
