export const en = {
  tag: "en-US",
  header: {
    title: "WYD {{server}} Ranking",
    description: "Discover the most insane players on the server",
    description2: "Players who have been inactive for more than 3 months will be removed from the ranking. If the player log in the game, they will automatically qualify to participate in the ranking.", 
  },
  categories: {
    title: "Categories",
    level: "Level",
    kills: "Kills",
    raWins: "Royal Arena Wins",
    raKills: "Royal Arena Kills",
    seasonLevel: "Season Server",
    fameGuild: "Fame Guild",
  },
  filters: {
    guildMark: "Guild",
    kingdom: "Kingdom",
  },
  remove: "Remove Filters",
  activefilter: "Active Filter",
};
